import { injectable } from "inversify";
import * as Mustache from "mustache";
import { Authenticator } from "../../../skupno/src/ts/authentication/authenticator";
import { CurrentUserInfo } from "../ts/models/current-user-info";
import { TranslationService } from "../ts/translation-service";
import { CurrentUserAccessor } from "../ts/utilities/current-user-accessor";
import template from "./profile-sidebar-view.html";

@injectable()
export class ProfileSidebarView {
    private rootElement: HTMLElement;
    private isLoaded: boolean = false;
    private isVisible: boolean = true;

    constructor(rootElement: HTMLElement,
        private currentUserAccessor: CurrentUserAccessor,
        private translationService: TranslationService,
        private authenticator: Authenticator    ) {
        this.rootElement = rootElement;
    }

    public set visible(isVisible: boolean) {
        this.isVisible = isVisible;
        console.log(this.isVisible);
        if (this.isLoaded) {
            if (window.matchMedia("(max-width: 767px)").matches) {
                // The viewport is less than 768 pixels wide
                //alert("This is a mobile device.");
            }
            else {
                $(this.rootElement).find(".profil-sidebar").toggle(isVisible);
            }
        }
    }

    /*
     * Wait for data then render it
     */
    public async load(): Promise<void> {
        try {

            // Try to get data
            //TODO: prebrati podatke
            // Render new content
            await this._renderData();

        } catch (e) {

            // Clear previous content on error
            //$('#main').text('Napaka pri nalaganju');
            throw e;
        }
    }

    private async _renderData(): Promise<void> {
        var currentUser = await this.currentUserAccessor.getUser();
        var viewModel: any;
        if (currentUser != null) {
            viewModel = {
                user: currentUser
            };
            viewModel.notificationTypes = this.prepareNotificationTypes(currentUser);
        }
        else {
            viewModel = {
                user: {
                    firstName: "",
                    lastName: "",
                    vlogaTitle: "",
                    inicialke: "",
                    authenticated: false
                }
            };
            viewModel.notificationTypes = new Array(0);
        }
        viewModel.translations = this.translationService.currentTranslations;
        // Construct a template
        const htmlTemplate = template;

        // Update the main elemnent's content in a manner that handles dangerous characters correctly
        const html = Mustache.render(htmlTemplate, viewModel);

        this.rootElement.innerHTML = html;
        //$(this.rootElement).find(".profil-sidebar").toggle(this.isVisible);

        $("[data-action=user-logout]").on("click", (ev) => {
            ev.preventDefault();
            this.authenticator.startLogout();
        });
        this.isLoaded = true;
    }

    private prepareNotificationTypes(userInfo: CurrentUserInfo) {
        if (!userInfo.allNotificationTypes) {
            return [];
        }
        var result = Array(userInfo.allNotificationTypes.length);
        for (let i = 0; i < userInfo.allNotificationTypes.length; i++) {
            var notificationType = userInfo.allNotificationTypes[i];
            result[i] = {
                id: notificationType.id,
                name: notificationType.name,
                isLocked: notificationType.isLocked == 1,
                selected: userInfo.selectedNotificationTypes.indexOf(notificationType.id) >= 0
            };
        }
        return result;
    }
}